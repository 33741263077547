export const appConstants = {
  OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
  CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
  OPEN_MESSAGE_MODAL: 'OPEN_MESSAGE_MODAL',
  CLOSE_MESSAGE_MODAL: 'CLOSE_MESSAGE_MODAL',
  OPEN_MESSAGE_MODAL_V2: 'OPEN_MESSAGE_MODAL_V2',
  CLOSE_MESSAGE_MODAL_V2: 'CLOSE_MESSAGE_MODAL_V2',
  OPEN_DOWNLOAD_MODAL: 'OPEN_DOWNLOAD_MODAL',
  CLOSE_DOWNLOAD_MODAL: 'CLOSE_DOWNLOAD_MODAL',
  OPEN_LANGUAGE_MODAL: 'OPEN_LANGUAGE_MODAL',
  CLOSE_LANGUAGE_MODAL: 'CLOSE_LANGUAGE_MODAL',
  SET_INITIALIZED: 'SET_INITIALIZED',
  SELECT_COMMUNITY_ITEM: 'SELECT_COMMUNITY_ITEM',
  CLEAR_SELECTED_COMMUNITY_ITEM: 'CLEAR_SELECTED_COMMUNITY_ITEM',
  SELECT_EDITOR_SOURCE: 'SELECT_EDITOR_SOURCE',
  CLEAR_SELECTED_EDITOR_SOURCE: 'CLEAR_SELECTED_EDITOR_SOURCE',
  OPEN_CUSTOM_MESSAGE_MODAL: 'OPEN_CUSTOM_MESSAGE_MODAL',
  CLOSE_CUSTOM_MESSAGE_MODAL: 'CLOSE_CUSTOM_MESSAGE_MODAL',
};

export const SubscriptionType = {
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  PREMIUM: 'PREMIUM',
  BASIC_YEARLY: 'BASIC_YEARLY',
  PRO_YEARLY: 'PRO_YEARLY',
  PREMIUM_YEARLY: 'PREMIUM_YEARLY',
  ADMIN: 'ADMIN',
  CUSTOM: 'CUSTOM',
};

export const SubscriptionAction = {
  CANCEL: 'CANCEL',
  INITIATE: 'INITIATE',
  UPGRADE: 'UPGRADE',
  ANNUALIZED_DOWNGRADE: 'ANNUALIZED_DOWNGRADE',
  DOWNGRADE: 'DOWNGRADE',
  CANCEL_DOWNGRADE: 'CANCEL_DOWNGRADE',
};

export const PaymentService = {
  STRIPE: 'stripe',
  ANDROID: 'iap_android',
  IOS: 'iap_ios',
  TOSS: 'toss',
};

export const Currency = {
  KRW: 'krw',
  USD: 'usd',
  THB: 'thb',
  JPY: 'jpy',
};

export const labelLength = {
  voice: 25,
  music: 100,
  vocalExtract: 50,
  speech: 50,
  videoEditor: 50,
  faceSwap: 50,
  image: 25,
};

export const ttsCharactersPerCredit = 10;

export const tabPrefix = {
  voice: 'trainingTab',
  music: 'inferenceTab',
  vocalExtract: 'vocalExtractorTab',
  speech: 'ttsInferenceTab',
  videoEditor: 'videoEditorTab',
  faceSwap: 'faceSwapTab',
  image: 'ttiInferenceTab',
};

export const statusCheckInterval = 5000;

// export const backendBaseUrl = 'http://localhost:80';
//export const backendBaseUrl = 'https://api.sorisori.ai';
export const backendBaseUrl = 'https://apibeta.sorisori.ai';

const TM_PORTONE_PURCHASE_CHANNEL_KEY = 'channel-key-be40510b-39c7-4a14-b004-0e12250a6e6e';
const TM_PORTONE_SUBSCRIPTION_CHANNEL_KEY = 'channel-key-8ff686d9-d11e-402d-95f9-67a5e848d7bf';
const PORTONE_PURCHASE_CHANNEL_KEY = 'channel-key-775590ef-bab8-4c9f-9e69-1f0fbb947049';
const PORTONE_SUBSCRIPTION_CHANNEL_KEY = 'channel-key-0aa79814-c5cb-467a-93ce-f04b38d191b0';

export const portOnePurchaseChannelKey =
  backendBaseUrl === 'https://api.sorisori.ai' ? PORTONE_PURCHASE_CHANNEL_KEY : TM_PORTONE_PURCHASE_CHANNEL_KEY;
export const portOneSubscriptionChannelKey =
  backendBaseUrl === 'https://api.sorisori.ai' ? PORTONE_SUBSCRIPTION_CHANNEL_KEY : TM_PORTONE_SUBSCRIPTION_CHANNEL_KEY;
export const revideoUrl = 'https://sori-video-player.s3.us-east-2.amazonaws.com/';
//export const revideoUrl = 'http://app.sorisori.ai/player/';
//export const revideoUrl = 'http://beta.sorisori.ai/player/';
