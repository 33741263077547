import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppBar, Toolbar, CircularProgress } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import AccountBox from '@mui/icons-material/AccountBox';
import LanguageIcon from '@mui/icons-material/Language';
import ExpandIcon from '@mui/icons-material/ExpandMore';

import Drawer from './ResponsiveDrawer';
import { useStyles } from './AppBarAndDrawer.styles';

import logo from '../../../img/textLogo.png';
import { useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { appActions } from '../../../actions/app.actions';
import { getFormattedLanguage } from '../../../utils/page.utils';
import { Link } from 'react-router-dom';
import { SubscriptionType } from '../../../constants/app.constants';

const ResponsiveDrawer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  console.log('user: ', user);
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [genCredit, setGenCredit] = useState(null);
  const [trainingCredit, setTrainingCredit] = useState(null);

  const formattedLanguage = getFormattedLanguage();
  const toggeleDrawer = () => setMobileOpen(!mobileOpen);
  const openLanguageModal = () => dispatch(appActions.openLanguageModal());

  const coverEndpoints = ['/generate-music', '/train-voice'];
  const veEndpoints = ['/vocal-extract'];
  const ttsEndpoints = ['/generate-tts', '/tts-train-voice'];
  const ttiEndpoints = ['/generate-tti'];
  const faceSwapEndpoints = ['/faceswap'];

  useEffect(() => {
    if (!user?.subscription) {
      setGenCredit(null);
      setTrainingCredit(null);
    } else if (user?.subscription && user?.subscription.type !== SubscriptionType.FREE) {
      console.log('hehrehrhere');
      setGenCredit(user.subscription.genCreditsRemaining);
      setTrainingCredit(user.subscription.trainingCreditsRemaining);
    } else if (user?.subscription && user?.subscription.type === SubscriptionType.FREE) {
      if (coverEndpoints.includes(pathname)) {
        setGenCredit(user.subscription.freeInferenceRemaining);
        setTrainingCredit(user.subscription.freeTrainingRemaining);
      } else if (ttsEndpoints.includes(pathname)) {
        setGenCredit(user.subscription.freeTtsInferenceRemaining);
        setTrainingCredit(user.subscription.freeTtsTrainingRemaining);
      } else if (ttiEndpoints.includes(pathname)) {
        setGenCredit(user.subscription.freeTtiInferenceRemaining);
        setTrainingCredit(0);
      } else if (veEndpoints.includes(pathname)) {
        setGenCredit(user.subscription.freeVocalExtractRemaining);
        setTrainingCredit(0);
      } else if (faceSwapEndpoints.includes(pathname)) {
        setGenCredit(user.subscription.freeFaceSwapRemaining);
        setTrainingCredit(0);
      } else {
        setGenCredit(null);
        setTrainingCredit(null);
      }
    } else {
      setGenCredit(null);
      setTrainingCredit(null);
    }
  }, [user?.subscription, pathname]);

  return (
    <div>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <MenuIcon onClick={toggeleDrawer} className={classes.menuButton} />
          <Link to={'/'}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Link>
          <div className={classes.space}></div>
          {/* <div className={classes.languageContainer} onClick={openLanguageModal}>
            <LanguageIcon />
            <div className={classes.selectedLanguage}>{formattedLanguage}</div>
            <ExpandIcon />
          </div> */}
          {genCredit !== null && (
            <div className={classes.creditContainer}>
              <div className={classes.genCredit}>
                {t('genCountText')}: {genCredit}
              </div>
              <div className={classes.traingCredit}>
                {t('trainingCountText')}: {trainingCredit}
              </div>
            </div>
          )}
          {user.loading && <CircularProgress />}
          {!user.loading &&
            (user.loggedIn ? (
              <div>
                <div className={classes.userNameContainer} onClick={() => navigate('/profile')}>
                  <AccountBox className={classes.userImg} />
                  <div className={classes.userName}>{user.name.toUpperCase()}</div>
                  <div style={{ display: 'none' }} id="user-id">
                    {user.id}
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.userNameContainer} onClick={() => dispatch(appActions.openLoginModal())}>
                <AccountBox className={classes.userImg} />
                <span className={classes.headerLoginText}>{t('signIn')}</span>
              </div>
            ))}
        </Toolbar>
      </AppBar>
      <AppBar className={classes.topBar}>
        {user.loading && <CircularProgress />}
        {!user.loading &&
          (user.loggedIn ? (
            <div className={classes.topBaerInnerContainer}>
              {genCredit !== null && (
                <div className={classes.creditContainer}>
                  <div className={classes.genCredit}>
                    {t('genCountText')}: {genCredit}
                  </div>
                  <div className={classes.traingCredit}>
                    {t('trainingCountText')}: {trainingCredit}
                  </div>
                </div>
              )}
              <div>
                <div className={classes.userNameContainer} onClick={() => navigate('/profile')}>
                  <AccountBox className={classes.userImg} />
                  <div className={classes.userName}>{user.name.toUpperCase()}</div>
                  <div style={{ display: 'none' }} id="user-id">
                    {user.id}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.userNameContainer} onClick={() => dispatch(appActions.openLoginModal())}>
              <AccountBox className={classes.userImg} />
              <span className={classes.headerLoginText}>{t('signIn')}</span>
            </div>
          ))}
      </AppBar>
      <Drawer open={mobileOpen} handleDrawerToggle={toggeleDrawer} />
    </div>
  );
};

export default ResponsiveDrawer;
